/**
 *
 * Ripspot Template Page
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import SEO from 'components/seo';
import RipspotMemorial from 'components/RipspotMemorial';

import { hydrateRipspot, setViewSingleRipspot } from 'actions/ripspots';
import { MAP_SEARCH_ROUTE } from 'utils/routes';

/**
 *  RipspotTemplate
 *
 * @param {*} { ripspotId }
 * @returns
 */

const RipspotTemplate = ({ pageContext: { ripspot }, dispatch }) => {
  const onViewRipspotClick = () => {
    // 1. hydrate ripspot (puts data in state for pins and edit info)
    dispatch(hydrateRipspot(ripspot));
    dispatch(setViewSingleRipspot(true));
    // 2. navigate to map
    // set bounding view box around all pins

    navigate(MAP_SEARCH_ROUTE, {
      state: {
        shouldBoundPins: true,
        viewRipspot: true,
      },
    });
  };

  const hasData = Object.keys(ripspot).length;

  let seoImage;
  if (ripspot.photoGallery.length > 0) {
    seoImage = ripspot.photoGallery[0].url;
  }

  return (
    <>
      <SEO
        title={`${ripspot.name} ${ripspot.birthDate.year || 'N/A'} - ${ripspot
          .deathDate.year || 'N/A'}`}
        image={seoImage}
        description={`In celebration of ${
          ripspot.name
        } and the life they lived. ${ripspot.birthDate.year ||
          'N/A'} - ${ripspot.deathDate.year || 'N/A'}`}
        uri={`/ripspot/${ripspot.id}`}
      />
      <Container isOpen={hasData}>
        <RipspotMemorial
          ripspot={ripspot}
          onViewRipspotClick={onViewRipspotClick}
        />
      </Container>
    </>
  );
};

RipspotTemplate.propTypes = {
  ripspotId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(memo(RipspotTemplate));

const Container = styled.div`
  ${({ theme }) => theme.styles.grow};
  color: ${({ theme }) => theme.colors.Primary};
`;
